import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faLink } from '@fortawesome/pro-regular-svg-icons';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { faFacebook, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import config from 'config';
import { useCampaignPage } from 'context/CampaignPage';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import Snackbar from 'components/common/Snackbar';
import Tiles from 'components/common/Tiles';

const ShareModal = ({ title, url, show, onHide }) => {
  const [copied, setCopied] = useState(false);
  const { userIsFundraiser, setIsInviting } = useCampaignPage();

  const router = useRouter();
  const shareUrl = useMemo(() => {
    const uri = url ?? router.asPath;
    return uri.includes('://') ? uri : config('/hostname') + uri;
  }, [url, router]);

  return (
    <>
      {copied && (
        <Snackbar
          type="success"
          message="Copied to clipboard!"
          onDismiss={() => setCopied(false)}
          duration="short"
        />
      )}
      <Modal show={show} onHide={onHide} className="md:max-w-sm">
        {title && (
          <header className="p-3 pb-0 flex items-center">
            <button
              type="button"
              className="flex w-10 h-10 justify-center items-center"
              onClick={onHide}
            >
              <FontAwesomeIcon icon={faTimes} size="lg" fixedWidth />
            </button>
            <h2 className="grow text-center pr-10 text-lg font-medium">{title}</h2>
          </header>
        )}
        <div className="p-6 pb-8">
          <Tiles columns={1} spacing={['xs', 'sm']}>
            <FacebookShareButton url={shareUrl} className="w-full">
              <Button as="div" color="facebook" className="w-full font-medium">
                <FontAwesomeIcon icon={faFacebook} size="lg" className="mr-3" /> Share on Facebook
              </Button>
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} className="w-full">
              <Button as="div" color="twitter" className="w-full font-medium">
                <FontAwesomeIcon icon={faXTwitter} size="lg" className="mr-3" /> Share on X
              </Button>
            </TwitterShareButton>
            {userIsFundraiser && (
              <Button
                as="button"
                type="button"
                color="green"
                className="w-full font-medium"
                onClick={() => {
                  setIsInviting(true);
                  onHide();
                }}
              >
                <FontAwesomeIcon icon={faPaperPlane} size="lg" className="mr-3" />
                Share via email or text
              </Button>
            )}
            <CopyToClipboard text={shareUrl} onCopy={() => setCopied(true)}>
              <Button
                as="button"
                type="button"
                color="gray-800"
                className="block w-full font-medium"
                outline
              >
                <FontAwesomeIcon icon={faLink} size="lg" className="mr-3" /> Copy page URL
              </Button>
            </CopyToClipboard>
          </Tiles>
        </div>
      </Modal>
    </>
  );
};

ShareModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
  title: PropTypes.node,
  url: PropTypes.string,
};

ShareModal.defaultProps = {
  show: false,
  title: 'Share this page',
  url: null,
};

export default ShareModal;
